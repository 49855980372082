<template>
  <div class="container" :style="height">
    <div class="title">结算中心</div>
    <div class="settlement">
      <el-radio v-model="radio" label="1">
        <img src="../assets/images/zhifubao.png" alt />
      </el-radio>
      <el-radio v-model="radio" label="2">
        <img src="../assets/images/weixinzhifu.png" alt />
      </el-radio>
      <el-radio v-model="radio" label="3">
        <img src="../assets/images/paypal.png" alt />
      </el-radio>
      <el-radio v-model="radio" label="4">
        <img src="../assets/images/yinhangka.png" alt />
        <em>国际信用卡</em>
      </el-radio>
      <div class="settlementprice">
        <p>${{paymoney}}</p>
        <a class="common-btn" @click="payMoneyClick(radio)">立即支付</a>
      </div>
    </div>
    <!-- 支付宝支付 -->
    <input type="hidden" id="stripe" v-model="alipay" />
    <!-- 扫码支付 -->
    <el-dialog
      :visible.sync="dialogTableVisible"
      :close-on-click-modal="false"
      :before-close="handleClose"
    >
      <h3 v-if="radio==1">支付宝扫码进行支付</h3>
      <h3 v-if="radio==2">微信扫码进行支付</h3>
      <p>金额：＄{{paymoney}}</p>
      <div id="qrcode"></div>
    </el-dialog>
    <!-- 银行卡支付 -->
    <el-dialog
      title="国际信用卡"
      :visible.sync="dialogCardVisible"
      class="cardcont"
      :close-on-click-modal="false"
    >
      <div id="app">
        <h3>请输入您的银行卡信息:</h3>
        <card
          class="stripe-card"
          :class="{ complete }"
          stripe="pk_live_WQui0sIpa18QIRnufkGpjscS00TjXb52EN"
          :options="stripeOptions"
          @change="complete = $event.complete"
        />
        <button class="pay-with-stripe" @click="isCardpay" :disabled="!complete">信用卡支付</button>
      </div>
    </el-dialog>
    <!-- 支付成功 -->
    <el-dialog :visible.sync="innerVisible">
      <div class="mb10">购买成功，请去手机APP上开启学习吧</div>
    </el-dialog>
    <!-- 加载中 -->
    <div class="loading" v-if="loadshow">
      <div>
        <img src="../assets/images/loading.gif" alt />
      </div>
    </div>
  </div>
</template>
<style>
.el-dialog__body h3 {
  padding-bottom: 10px;
}
#qrcode {
  display: inline-block;
}
#qrcode img {
  width: 132px;
  height: 132px;
  background-color: #fff;
  padding: 6px;
}
.cardcont .el-dialog {
  width: 550px;
}
.cardcont h3 {
  text-align: left;
}
.pay-with-stripe {
  background: rgba(255, 108, 108, 1);
  color: #fff;
  font-size: 15px;
  margin-top: 20px;
  width: 160px;
  height: 40px;
  line-height: 40px;
  text-align: center;
  border: none;
  border-radius: 5px;
  cursor: pointer;
}
.loading {
  width: 100%;
  text-align: center;
  position: fixed;
  left: 0;
  top: 0;
  z-index: 999999;
  height: 100%;
  background: rgba(0, 0, 0, 0.4);
}
.loading div {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translateX(-50%) translateY(-50%);
  -moz-transform: translateX(-50%) translateY(-50%); /* Firefox */
  -webkit-transform: translateX(-50%) translateY(-50%); /* Safari 和 Chrome */
  -ms-transform: translateX(-50%) translateY(-50%); /* Internet Explorer */
  -o-transform: translateX(-50%) translateY(-50%);
  background: rgba(255, 255, 255, 1);
  width: 200px;
  height: 200px;
  line-height: 200px;
  opacity: 0.5;
  border-radius: 5px;
}
</style>
<script src="https://code.jquery.com/jquery-3.1.1.min.js"></script>
<script src="https://js.stripe.com/v3/"></script>
<script>
// 支付宝支付
import QRCode from "qrcodejs2";
// import "../assets/js/jquery.qrcode.min.js";
// 支付宝支付，微信支付,paypal支付,银行,立即支付
import { alipayPay, wechatPay, pay, cardPay } from "@/assets/js/api";
// 银行卡支付
// import { stripeKey, stripeOptions } from "./stripeConfig.json";
import { Card, createToken } from "vue-stripe-elements-plus";
export default {
  name: "settlement",
  data() {
    return {
      height: { height: "" },
      radio: "1",
      dialogTableVisible: false,
      dialogCardVisible: false,
      innerVisible: false,
      paymoney: "",
      PayCode: "",
      // 支付宝支付
      alipay: "pk_live_WQui0sIpa18QIRnufkGpjscS00TjXb52EN",
      totalAmount: "",
      tradeno: "",
      timer: "",
      // 国际信用卡
      appUesrCourseId: "",
      complete: false,
      stripeOptions: {},
      stripeToken: "",
      // 加载中
      loadshow: false
    };
  },
  components: { Card },
  methods: {
    // 信用卡支付
    isCardpay: function() {
      createToken().then(data => {
        this.stripeToken = data.token.id;
        if (this.stripeToken != "") {
          this.totalAmount = parseFloat(this.paymoney) * 100;
          cardPay({
            stripeToken: this.stripeToken,
            price: this.totalAmount,
            appUesrCourseId: this.appUesrCourseId
          }).then(result => {
            if (result.code == 0) {
              this.$router.push("/usermain");
            }
          });
        }
      });
    },

    // 可视区域高度
    getHight() {
      this.height.height = window.innerHeight - 180 + "px";
    },
    handleClose() {
      (document.getElementById("qrcode").innerHTML = ""),
        (this.dialogTableVisible = false);
      clearInterval(this.timer); //清除定时器
    },
    // 立即支付
    payMoneyClick(type) {
      if (!this.isProduction) {
        return;
      }
      if (type == 1) {
        this.loadshow = true;
        this.totalAmount = parseFloat(this.paymoney) * 100;
        var stripe = Stripe(this.alipay);
        let _this = this;
        var stripeSource = stripe
          .createSource({
            type: "alipay",
            amount: this.totalAmount,
            currency: "usd",
            redirect: {
              return_url: this.alipayUrl
            }
          })
          .then(function(result) {
            if (
              result &&
              result.source &&
              result.source.redirect &&
              result.source.redirect["url"]
            ) {
              _this.dialogTableVisible = true;
              _this.tradeno = result.source.id;
              _this.loadshow = false;
              setTimeout(() => {
                let qrcode = new QRCode("qrcode", {
                  width: 132,
                  height: 132,
                  text: result.source.redirect["url"] // 二维码地址
                });
              }, 100);
              _this.timer = setInterval(_this.isalipayPay, 5000); //每隔5000毫秒请求一次接口
            }
          });
      } else if (type == 2) {
        this.loadshow = true;
        this.totalAmount = parseFloat(this.paymoney) * 100;
        var stripe = Stripe(this.alipay);
        let _this = this;
        var stripeSource = stripe
          .createSource({
            type: "wechat",
            amount: this.totalAmount,
            currency: "usd"
          })
          .then(function(result) {
            if (
              result &&
              result.source &&
              result.source.wechat &&
              result.source.wechat["qr_code_url"]
            ) {
              _this.dialogTableVisible = true;
              _this.tradeno = result.source.id;
              _this.loadshow = false;
              // console.log(
              //   "wx========",
              //   result,
              //   result.source.wechat,
              //   _this.loadshow
              // );
              setTimeout(() => {
                let qrcode = new QRCode("qrcode", {
                  width: 132,
                  height: 132,
                  text: result.source.wechat["qr_code_url"] // 二维码地址
                });
              }, 100);
              _this.timer = setInterval(_this.iswechatPay, 5000); //每隔5000毫秒请求一次接口
            }
          });
      } else if (type == 3) {
        this.isPaypal();
      } else if (type == 4) {
        this.dialogCardVisible = true;
        // this.isCardpay();
      }
    },
    isalipayPay: function() {
      alipayPay({
        price: this.totalAmount,
        tradeno: this.tradeno,
        appUesrCourseId: this.appUesrCourseId
      }).then(result => {
        if (result.code == 0) {
          this.$message({
            message: "支付成功",
            type: "success"
          });
          clearInterval(this.timer); //清除定时器
          this.$router.push("/usermain");
          // this.innerVisible = true;
        }
      });
    },
    iswechatPay: function() {
      wechatPay({
        price: this.totalAmount,
        tradeno: this.tradeno,
        appUesrCourseId: this.appUesrCourseId
      }).then(result => {
        if (result.code == 0) {
          this.$message({
            message: "支付成功",
            type: "success"
          });
          clearInterval(this.timer); //清除定时器
          this.$router.push("/usermain");
          // this.innerVisible = true;
        }
      });
    },
    isPaypal: function() {
      pay({
        price: this.paymoney,
        appUserCourseId: this.appUesrCourseId
      }).then(result => {
        if (result.code == 0) {
          window.location.href = result.url;
        } else {
          this.$message.error(result.msg);
        }
      });
    }
  },
  created() {
    this.getHight();
    window.addEventListener("resize", this.getHight);
    this.paymoney = localStorage.getItem("paymoney");
    this.appUesrCourseId = this.$route.query.id;
  },
  destroyed() {
    window.removeEventListener("resize", this.getHight);
  },
  mounted() {}
};
</script>
